import * as React from 'react';

import config from '../../config.json';
import { HeadingWithIcon } from './heading-with-icon';
import { PhoneMailIcon } from './vectors';

function ContactDetails() {
  return (
    <>
      <HeadingWithIcon>
        <HeadingWithIcon.Heading>Let’s Talk</HeadingWithIcon.Heading>
        <HeadingWithIcon.Icon hidden>
          <PhoneMailIcon />
        </HeadingWithIcon.Icon>
      </HeadingWithIcon>
      <h3 className="mt-2 heading-3">Contact Details</h3>
      <div className="mt-2 prose">
        <dl className="space-y-4">
          <div>
            <dt className="inline">Location: </dt>
            <dd className="inline">
              {config.address.line1},{' '}
              <span className="inline-block">{config.address.line2}</span>
            </dd>
          </div>
          <div>
            <dt className="inline">Phone: </dt>
            <dd className="inline">
              <a href={`tel:${config.phone}`}>{config.phone}</a>
            </dd>
          </div>
          <div>
            <dt className="inline">Email: </dt>
            <dd className="inline">
              <a href={`mailto:${config.email}`}>{config.email}</a>
            </dd>
          </div>
          <div>
            <dt className="inline">Opening Hours: </dt>
            <dd className="inline">{config.openingHours}</dd>
          </div>
        </dl>
      </div>
      <h3 className="mt-6 heading-3">Port City Courtesy Bus</h3>
      <div className="mt-2 prose">
        <p>
          To book, please call the club’s reception. <br />
          Download courtesy bus timetable{' '}
          <a
            href="/new-courtesy-bus-timetable.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="uppercase"
          >
            here
          </a>
          .
        </p>
      </div>
    </>
  );
}

export { ContactDetails };
