import * as React from 'react';

import config from '../../config.json';
import { useLazyLoad } from '../hooks/use-lazyload';

function GoogleMap() {
  const {
    containerRef,
    srcRef,
    isLoaded,
    handleIsLoaded,
    Spinner,
  } = useLazyLoad();
  return (
    <div
      ref={containerRef}
      className="flex lg:inset-0 lg:absolute bg-blue-light aspect-w-16 aspect-h-9 lg:pb-0"
    >
      <iframe
        ref={srcRef}
        data-src={config.address.googleMapsEmbed}
        onLoad={handleIsLoaded}
        allowFullScreen
        aria-hidden
        title={`${config.title} Google Maps location`}
        className="flex-1"
      />
      {isLoaded ? null : <Spinner />}
    </div>
  );
}

export { GoogleMap };
