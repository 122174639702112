import * as React from 'react';

import { BackgroundTexture } from '.';
import { ContactDetails } from './contact-details';
import { ContactForm } from './contact-form';
import { GoogleMap } from './google-map';
import { SideBySide } from './side-by-side';

type TContactFormWithMap = {
  isReversed?: boolean;
  sideComponent?: () => React.ReactElement;
  background?: 'none' | 'blue' | 'grunge';
};

function ContactFormWithMap({
  isReversed = false,
  sideComponent = GoogleMap,
  background = 'none',
}: TContactFormWithMap) {
  return (
    <div className="py-12">
      <div className="relative">
        {background === 'grunge' ? <BackgroundTexture overflowBottom /> : null}
        <SideBySide
          isReversed={isReversed}
          sideComponent={React.createElement(sideComponent || GoogleMap)}
        >
          <div className="relative flex h-full text-blue-dark">
            {background === 'blue' ? (
              <div aria-hidden className="absolute inset-0 bg-blue-light" />
            ) : null}

            <div className="relative flex flex-col justify-center flex-1 w-full px-4 py-16 mx-auto max-w-prose">
              <ContactDetails />
              <ContactForm />
            </div>
          </div>
        </SideBySide>
      </div>
    </div>
  );
}

export { ContactFormWithMap };
